// For large screen
@include media-breakpoint-up(lg) {
    .mt-lg-high{
        margin-top: 4.5vw;
    }
    .mt-lg-5rem{
        margin-top: 5rem;
    }
    .w-lg-45{
        width: 46%;
    }
    .w-lg-55{
        width: 54%;
    }
    .testimonials-div {
        .container{
            left: 9%;
        }
    }

    .esim-div{
        #useESIM1{
            .phone-img-1{
                margin-left: 4vw;
            }
        }
    }
    .plan-container{
        max-height: 76vh;
    }
    .show-all-result-section{
        max-height: 50vh;
    }
}

// For large screen
@include media-breakpoint-between(xl, xxl) {
    .testimonials-div {
        .relative {
            left: 0 !important;
            bottom: 4.8vw !important;
            .phone-img {
                height: 37vw !important;
            }
        }
    }
    .esim-div{
        #useESIM1{
            .phone-img-1{
                margin-left: -5.2vw;
            }
        }
    }
    

}