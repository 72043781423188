// Modal Common CSS
.add-edit-modal{
    padding: 24px 20px;    
    font-family: var(--font-family-sf-pro-display);
    background-color: #fff !important;
    border-radius: 16px !important;

    .btn{
        height: 44px;
        width: 170px;
        border: none;
        font-size: 16px;
        line-height: 24px;
        font-weight: 600;
        padding: 10px 18px;
    }
    .btn-secondary{
        background: var(--cancel-button-background-color);
        color: #000;
        border: 1px solid var(--cancel-button-border-color);
    }
    .btn-primary{
        background: var(--primary-main, #6365EF) !important;
        color: #FFF !important;

        &:hover{
            background-color: var(--primary-main, #6365EF) !important;
        }
    }
    
    .btn-danger{
        color: #FFF;
        background: var(--delete-button-bg-color);
    }
    .btn-success{
        --bs-btn-bg: #2D9563 !important;
    }
    .btn-warning{
        --bs-btn-bg: #fff3cd !important;
    }
    .btn-full-width {
        width: 100% !important;
    }
    .warning-svg-icon{
        rect:first-child{
            fill: #fff3cd !important;
        }
        g path{
            fill: #ffca2c !important;
        }
        rect:last-child{
            stroke: #ffca2c !important;
        }
    }
}

.plan-details-modal{
    font-family: "tt-firs-neue-trial-regular" !important;
    height: 23.39vw;
    .heading{
        font-family: "tt-firs-neue-trial-medium" !important;
        font-size: 2.60vw;
        font-style: normal;
        // font-weight: 700;
        line-height: 3.13vw;
        letter-spacing: -1px;
    }
    .sub-heading{
        font-size: 1.46vw;
        font-style: normal;
        font-family: "tt-firs-neue-trial-demibold" !important;
        // font-weight: 700;
        line-height: 1.98vw;
        letter-spacing: -0.56px;
    }
    .price{
        // font-size: 50px;
        // font-style: normal;
        // font-weight: 700;
        // line-height: 60px; /* 120% */
        // letter-spacing: -1px;
        font-family: "tt-firs-neue-trial-medium" !important;
        font-size: 2.60vw;
        font-style: normal;
        // font-weight: 700;
        line-height: 3.13vw;
        letter-spacing: -1px;
        margin-bottom: 0 !important;
        .currency-symbol{
            font-family: "tt-firs-neue-trial-medium" !important;
            font-size: 1.46vw;
            font-style: normal;
            // font-weight: 700;
            line-height: 1.98vw;
            letter-spacing: -0.56px;
        }
    }
    .message{
        font-family: "sf-pro-regular" !important;
        font-size: 0.83vw;
        font-style: normal;
        font-weight: 400;
        line-height: 1.77vw;
        letter-spacing: -0.32px;
    }
    .right-section{
        // padding: 35px 25px;
        padding: 1.88vw 1.30vw;
        .plan-description{
            color: var(--text-color, #303030);
            font-size: 1.04vw;
            font-style: normal;
            font-weight: 500;
            line-height: 1.88vw;
            letter-spacing: -0.4px;
            margin-top: 1vw;
            font-family: "tt-firs-neue-trial-demibold" !important;
        }
        .btn{
            width: 18.02vw;
            padding: 13px 42px;
        }
    }
    
    .bottom-section{
        bottom: 1.2rem;
        position: absolute;
    }
    .btn-section{
        height: 13vh;
    }
}